<template>
    <div class="order-pdf-detail">
        <div class="container">
            <order-detail-meta :id="id" mode="pdf"></order-detail-meta>
        </div>
    </div>
</template>

<script>
import OrderDetailMeta from "@/components/Order/OrderDetailMeta";

export default {
    props: ["id"],
    components: {
        OrderDetailMeta
    }
};
</script>
<style lang="less">
</style>